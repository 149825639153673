<template>
    <header class="bg-blue-900">
        <div class="flex items-start justify-center w-full h-20">
            <div class="self-center flex-1 ml-2">
                <label for="toggle"
                       class="flex items-center cursor-pointer">
                    <div class="flex-initial flex-grow-0 mr-2 text-2xl"
                         :class="showDark? 'text-gray-400': 'text-yellow-400'"><i class="fas fa-sun"></i></div>
                    <!-- toggle -->
                    <div class="relative">
                        <!-- input -->
                        <input type="checkbox"
                               id="toggle"
                               @click="setShowDark(!showDark)"
                               class="sr-only">
                        <!-- line -->
                        <div class="block w-12 h-6 bg-yellow-500 rounded-full line"></div>
                        <!-- dot -->
                        <div class="absolute w-4 h-4 transition bg-white rounded-full dot left-1 top-1"></div>
                    </div>
                    <!-- label -->
                    <div class="ml-2 text-2xl"
                         :class="showDark? 'text-blue-200': 'text-gray-400'"><i class="fas fa-moon"></i></div>
                </label>
            </div>
            <div class="self-center flex-1 sm:hidden">
                <div class="flex justify-start">
                    <div class="flex-initial">
                        <img class="w-10"
                             src="../img/logo.svg"
                             alt="">
                    </div>
                    <div class="self-center text-white">
                        <h1 class="text-sm PattayaFont">Enjoy The Time</h1>
                        <h1 class="text-sm font-normal NotoSansTCFont">享時光</h1>
                    </div>
                </div>
            </div>
            <div class="mr-5 sm:mr-5 sm:flex-grow-0"
                 :class="portfolioShow ? 'self-start mt-6' : 'self-center'">
                <span class="text-white desktopShow sm:static sm:mr-2"
                      v-if="user.userable !== undefined">{{user.userable.name}}</span>
                <span @click="clickPortfolio()"
                      class="p-2 text-blue-500 bg-white rounded-full cursor-pointer md:p-5">
                    <i class="text-lg text-blue-500 md:text-2xl fas fa-user"></i>
                </span>
                <div class="absolute top-16 right-4"
                     :class="portfolioShow ? 'translate-x-0 opacity-1': 'translate-x-0 opacity-0 sr-only'">
                    <ul class="w-40 pb-3 mt-5 transition duration-500 transform bg-white rounded-lg shadow-2xl sm:py-5">
                        <li class="p-3 text-center text-gray-400 border-b border-blue-900 sm:hidden"
                            v-if="user.userable !== undefined">{{user.userable.name}}</li>
                        <li class="px-5 py-3 mb-2 text-center transition duration-100 border-b border-blue-900 cursor-pointer hover:text-blue-500"
                            @click="$router.push({name:'userUpdate'})"><span class="mr-2 text-blue-500"><i class="fas fa-edit"></i></span>更新資料</li>
                        <li class="px-3 py-2 mx-2 text-lg text-center text-white transition duration-300 bg-red-700 cursor-pointer rounded-2xl hover:bg-red-900"
                            @click="logOut()">登出 <i class="fas fa-sign-out-alt"></i></li>
                    </ul>
                </div>
            </div>
            <div class="self-center mobileShow">
                <button class="inline-block p-3 mr-2 text-blue-500 bg-white rounded-lg"
                        @click="setShowMenu(!showMenu)">
                    <i class="fas fa-bars fa-2x"></i>
                </button>
            </div>
        </div>
    </header>
</template>

<style scoped lang="scss">
input:checked ~ .dot {
    transform: translateX(140%);
    background-color: theme("colors.white");
}
input:checked ~ .line {
    background-color: theme("colors.gray.900");
}
</style>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    computed: {
        ...mapState(["showDark", "showMenu"]),
        ...mapState("userStore", ["user"])
    },
    data() {
        return {
            portfolioShow: false
        };
    },
    methods: {
        ...mapActions("userStore", ["logOutUser"]),
        ...mapMutations(["setShowDark", "setShowMenu"]),
        clickPortfolio() {
            this.portfolioShow = !this.portfolioShow;
        },
        logOut() {
            let self = this;
            self.logOutUser();
            self.$router.push({ name: "login" });
            location.reload();
        }
        // clickThemeChange() {
        //     this.setShowDark(!this.showDark);
        // }
    }
};
</script>